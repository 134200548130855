import { AppState, HomePageDubaiAppState } from '../../../initialStateRegistration';
import { HomePageDubaiMinorSections, HomePageDubaiTopSection } from './homePageDubai.initialState';

export const getHomePage = (state: AppState) => (state as HomePageDubaiAppState).template;

export const getSectionDataByKey = (key: HomePageDubaiTopSection | HomePageDubaiMinorSections) => (state: AppState) => getHomePage(state)[key];

export const getSectionArticlesByKey = (key: HomePageDubaiTopSection | HomePageDubaiMinorSections) => (state: AppState) => getHomePage(state)[key].articles;

export const getSectionTitleByKey = (key: HomePageDubaiMinorSections) => (state: AppState) => getHomePage(state)[key].sectionTitle;

export const getMoreButtonTextByKey = (key: HomePageDubaiMinorSections) => (state: AppState) => getHomePage(state)[key].moreButtonText;

export const getMoreButtonSvgByKey = (key: HomePageDubaiMinorSections) => (state: AppState) => getHomePage(state)[key].moreButtonSvg;

export const getMetadataDescription = (state: AppState) => getHomePage(state).metadataDescription;

export const getMetadataTitle = (state: AppState) => getHomePage(state).metadataTitle;

export const getMetadataImage = (state: AppState) => getHomePage(state).metadataImage;

export const getHreflangData = (state: AppState) => getHomePage(state).hreflangData;
