import React from 'react';
import { useSelector } from 'react-redux';
import {
  Ad,
  AppContent,
  baseUnit,
  ErrorBoundary,
  FourVCOneOTC,
  MEDIA_BREAKPOINTS,
  NineHCForOneThird,
  OneOnTopThreeHorizontalArticleList,
  OneOTCFourVC,
  OneTwoOnTopFullWidth,
  SecondarySectionTitle,
  Section,
  SectionHeader,
  SectionWithTitle,
  SixHCForTwoThirds,
  ThreeHCOneOTC,
  ThreeThreeVC,
  TwoThirdsOneThirdWrapper,
} from 'mm-ui-components';
import { Store } from 'redux';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Theme } from '../../components/connectedComponents/Theme';
import { MetaTags } from '../../components/connectedComponents/MetaTags';
import { HeadScripts } from '../../components/connectedComponents/HeadScripts';
import { SECTION_NAMES, SLOT_NAMES } from '../templates.const';
import { Page } from '../Page';
import {
  getMetadataDescription,
  getMetadataImage,
  getMetadataTitle,
  getMoreButtonSvgByKey,
  getMoreButtonTextByKey,
  getSectionArticlesByKey,
  getSectionTitleByKey,
  getHreflangData,
} from '../../store/template/homePage/homePageDubai/homePageDubai.selectors';
import { getAdSlotId, getAdSlotOnSuccess } from '../../store/config/config.selectors';
import { ErrorReporter } from '../../errorReporting/errorReporter.type';
import { AppActions, AppState } from '../../store/initialStateRegistration';
import { useMeasureHydrateTime } from '../useMeasureHydrateTime';
import { NavigationLayout } from '../../components/connectedComponents/NavigationLayout';

interface HomePageDubaiProps {
  store: Store<AppState, AppActions>;
  errorProvider: ErrorReporter;
}

const getArticlesInTemplate = (state: AppState) => {
  const topSectionArticles = getSectionArticlesByKey(SECTION_NAMES.TOP_SECTION)(state) || [];
  const cardsSectionArticles = getSectionArticlesByKey(SECTION_NAMES.CARDS_SECTION)(state) || [];
  const contentSection1Articles = getSectionArticlesByKey(SECTION_NAMES.CONTENT_SECTION_1)(state) || [];
  const contentSection2Articles = getSectionArticlesByKey(SECTION_NAMES.CONTENT_SECTION_2)(state) || [];
  const contentSection3Articles = getSectionArticlesByKey(SECTION_NAMES.CONTENT_SECTION_3)(state) || [];
  return [
    ...topSectionArticles,
    ...cardsSectionArticles,
    ...contentSection1Articles,
    ...contentSection2Articles,
    ...contentSection3Articles,
  ];
};

export const HomePageDubaiComponent: React.FunctionComponent<HomePageDubaiProps> = ({ store, errorProvider }) => {
  useMeasureHydrateTime();
  return (
    <Page store={store} errorProvider={errorProvider}>
      <ErrorBoundary>
        <HomePageDubaiContentConnected />
      </ErrorBoundary>
    </Page>
  );
};

const createStyle = () => StyleSheet.create({
  sectionsWrapper: {
    [MEDIA_BREAKPOINTS.large]: {
      gridRowGap: `${baseUnit * 3}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      gridRowGap: `${baseUnit * 2}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      gridRowGap: `${baseUnit * 2}px`,
    },
    display: 'grid',
    gridTemplateColumns: '1fr',
    marginTop: `${baseUnit * 0.25}px`,
    marginBottom: `${baseUnit * 0.5}px`,
    justifyItems: 'center',
  },
});

export const HomePageDubaiContentConnected: React.FunctionComponent = () => {
  const topSectionArticles = useSelector(getSectionArticlesByKey(SECTION_NAMES.TOP_SECTION));
  const cardsSectionDisplayName = useSelector(getSectionTitleByKey(SECTION_NAMES.CARDS_SECTION));
  const cardsSectionArticles = useSelector(getSectionArticlesByKey(SECTION_NAMES.CARDS_SECTION));
  const cardsSectionMoreButtonSvg = useSelector(getMoreButtonSvgByKey(SECTION_NAMES.CARDS_SECTION));
  const cardsSectionMoreButtonText = useSelector(getMoreButtonTextByKey(SECTION_NAMES.CARDS_SECTION));
  const contentSection1DisplayName = useSelector(getSectionTitleByKey(SECTION_NAMES.CONTENT_SECTION_1));
  const contentSection1Articles = useSelector(getSectionArticlesByKey(SECTION_NAMES.CONTENT_SECTION_1));
  const contentSection1nMoreButtonSvg = useSelector(getMoreButtonSvgByKey(SECTION_NAMES.CONTENT_SECTION_1));
  const contentSection1MoreButtonText = useSelector(getMoreButtonTextByKey(SECTION_NAMES.CONTENT_SECTION_1));
  const contentSection2DisplayName = useSelector(getSectionTitleByKey(SECTION_NAMES.CONTENT_SECTION_2));
  const contentSection2Articles = useSelector(getSectionArticlesByKey(SECTION_NAMES.CONTENT_SECTION_2));
  const contentSection2MoreButtonSvg = useSelector(getMoreButtonSvgByKey(SECTION_NAMES.CONTENT_SECTION_2));
  const contentSection2MoreButtonText = useSelector(getMoreButtonTextByKey(SECTION_NAMES.CONTENT_SECTION_2));
  const contentSection3DisplayName = useSelector(getSectionTitleByKey(SECTION_NAMES.CONTENT_SECTION_3));
  const contentSection3Articles = useSelector(getSectionArticlesByKey(SECTION_NAMES.CONTENT_SECTION_3));
  const contentSection3MoreButtonSvg = useSelector(getMoreButtonSvgByKey(SECTION_NAMES.CONTENT_SECTION_3));
  const contentSection3MoreButtonText = useSelector(getMoreButtonTextByKey(SECTION_NAMES.CONTENT_SECTION_3));
  const contentSection4DisplayName = useSelector(getSectionTitleByKey(SECTION_NAMES.CONTENT_SECTION_4));
  const contentSection4Articles = useSelector(getSectionArticlesByKey(SECTION_NAMES.CONTENT_SECTION_4));
  const contentSection4MoreButtonSvg = useSelector(getMoreButtonSvgByKey(SECTION_NAMES.CONTENT_SECTION_4));
  const contentSection4MoreButtonText = useSelector(getMoreButtonTextByKey(SECTION_NAMES.CONTENT_SECTION_4));
  const contentSection5DisplayName = useSelector(getSectionTitleByKey(SECTION_NAMES.CONTENT_SECTION_5));
  const contentSection5Articles = useSelector(getSectionArticlesByKey(SECTION_NAMES.CONTENT_SECTION_5));
  const contentSection5MoreButtonSvg = useSelector(getMoreButtonSvgByKey(SECTION_NAMES.CONTENT_SECTION_5));
  const contentSection5MoreButtonText = useSelector(getMoreButtonTextByKey(SECTION_NAMES.CONTENT_SECTION_5));
  const contentSection6DisplayName = useSelector(getSectionTitleByKey(SECTION_NAMES.CONTENT_SECTION_6));
  const contentSection6Articles = useSelector(getSectionArticlesByKey(SECTION_NAMES.CONTENT_SECTION_6));
  const contentSection6MoreButtonSvg = useSelector(getMoreButtonSvgByKey(SECTION_NAMES.CONTENT_SECTION_6));
  const contentSection6MoreButtonText = useSelector(getMoreButtonTextByKey(SECTION_NAMES.CONTENT_SECTION_6));
  const contentSection7DisplayName = useSelector(getSectionTitleByKey(SECTION_NAMES.CONTENT_SECTION_7));
  const contentSection7Articles = useSelector(getSectionArticlesByKey(SECTION_NAMES.CONTENT_SECTION_7));
  const contentSection7MoreButtonSvg = useSelector(getMoreButtonSvgByKey(SECTION_NAMES.CONTENT_SECTION_7));
  const contentSection7MoreButtonText = useSelector(getMoreButtonTextByKey(SECTION_NAMES.CONTENT_SECTION_7));
  const articles = useSelector(getArticlesInTemplate);
  const topAdId = useSelector(getAdSlotId(SLOT_NAMES.TOP_AD));
  const oneOnOneId = useSelector(getAdSlotId(SLOT_NAMES.ONE_ON_ONE));
  const topAdOnSuccess = useSelector(getAdSlotOnSuccess(SLOT_NAMES.TOP_AD));
  const oneOnOneOnSuccess = useSelector(getAdSlotOnSuccess(SLOT_NAMES.ONE_ON_ONE));
  const metaTitle = useSelector(getMetadataTitle);
  const metaDescription = useSelector(getMetadataDescription);
  const pageImage = useSelector(getMetadataImage);
  const hreflangData = useSelector(getHreflangData) || [];
  const style = createStyle();

  return (
    <React.Fragment>
      <MetaTags articles={articles} title={metaTitle} description={metaDescription} pageImage={pageImage} alternateUrls={hreflangData} />
      <HeadScripts />
      <Theme>
        <AppContent>
          <NavigationLayout mainWrapperConfigThemeKey="mainContent">
            <Ad id={topAdId} onSuccess={topAdOnSuccess} setMinHeight />
            <div className={css(style.sectionsWrapper)}>

              <Section themeConfigurationPropKey="firstSectionMaxWidth">
                <OneTwoOnTopFullWidth cards={topSectionArticles} />
              </Section>

              <SectionWithTitle>
                <SectionHeader moreButtonSvg={cardsSectionMoreButtonSvg} moreButtonText={cardsSectionMoreButtonText}>
                  <SecondarySectionTitle>
                    {cardsSectionDisplayName}
                  </SecondarySectionTitle>
                </SectionHeader>
                <FourVCOneOTC cards={cardsSectionArticles} />
              </SectionWithTitle>

              <SectionWithTitle>
                <SectionHeader
                  moreButtonSvg={contentSection1nMoreButtonSvg}
                  moreButtonText={contentSection1MoreButtonText}
                >
                  <SecondarySectionTitle>
                    {contentSection1DisplayName}
                  </SecondarySectionTitle>
                </SectionHeader>
                <OneOTCFourVC cards={contentSection1Articles} />
              </SectionWithTitle>

              <SectionWithTitle>
                <SectionHeader
                  moreButtonSvg={contentSection2MoreButtonSvg}
                  moreButtonText={contentSection2MoreButtonText}
                >
                  <SecondarySectionTitle>
                    {contentSection2DisplayName}
                  </SecondarySectionTitle>
                </SectionHeader>
                <ThreeThreeVC cards={contentSection2Articles} />
              </SectionWithTitle>

              <TwoThirdsOneThirdWrapper>
                <SectionWithTitle>
                  <SectionHeader
                    moreButtonSvg={contentSection3MoreButtonSvg}
                    moreButtonText={contentSection3MoreButtonText}
                    paddingFactors={{ large: 0, medium: 0, small: 0 }}
                  >
                    <SecondarySectionTitle>
                      {contentSection3DisplayName}
                    </SecondarySectionTitle>
                  </SectionHeader>
                  <SixHCForTwoThirds cards={contentSection3Articles} />
                </SectionWithTitle>

                <SectionWithTitle>
                  <SectionHeader
                    moreButtonSvg={contentSection4MoreButtonSvg}
                    moreButtonText={contentSection4MoreButtonText}
                    paddingFactors={{ large: 0, medium: 0, small: 0 }}
                  >
                    <SecondarySectionTitle>
                      {contentSection4DisplayName}
                    </SecondarySectionTitle>
                  </SectionHeader>
                  <NineHCForOneThird
                    cards={contentSection4Articles}
                  />
                </SectionWithTitle>
              </TwoThirdsOneThirdWrapper>

              <SectionWithTitle>
                <SectionHeader
                  moreButtonSvg={contentSection5MoreButtonSvg}
                  moreButtonText={contentSection5MoreButtonText}
                >
                  <SecondarySectionTitle>
                    {contentSection5DisplayName}
                  </SecondarySectionTitle>
                </SectionHeader>
                <ThreeThreeVC cards={contentSection5Articles} />
              </SectionWithTitle>

              <SectionWithTitle>
                <SectionHeader
                  moreButtonSvg={contentSection6MoreButtonSvg}
                  moreButtonText={contentSection6MoreButtonText}
                >
                  <SecondarySectionTitle>
                    {contentSection6DisplayName}
                  </SecondarySectionTitle>
                </SectionHeader>
                <OneOnTopThreeHorizontalArticleList cards={contentSection6Articles} />
              </SectionWithTitle>

              <SectionWithTitle>
                <SectionHeader
                  moreButtonSvg={contentSection7MoreButtonSvg}
                  moreButtonText={contentSection7MoreButtonText}
                >
                  <SecondarySectionTitle>
                    {contentSection7DisplayName}
                  </SecondarySectionTitle>
                </SectionHeader>
                <ThreeHCOneOTC cards={contentSection7Articles} />
              </SectionWithTitle>

            </div>
            <Ad id={oneOnOneId} onSuccess={oneOnOneOnSuccess} />
          </NavigationLayout>
        </AppContent>
      </Theme>
    </React.Fragment>
  );
};
